import {
    AdditiveBlending, BackSide,
    Color,
    Mesh,
    MeshStandardMaterial,
    Scene,
    SphereGeometry,
    TextureLoader
} from "three";

export default class Earth {
    private readonly earth: Mesh
    private readonly clouds: Mesh
    private readonly atmosphere: Mesh;
    constructor(scene: Scene) {
        const textureLoader = new TextureLoader();
        const earthTexture = textureLoader.load('./Blue_Marble_2002.webp');
        const waterMaskTextureInv = textureLoader.load('./water_reflectiveness.webp');

        const earthMaterial = new MeshStandardMaterial({
            map: earthTexture,
            roughnessMap: waterMaskTextureInv,
        });
        const earthSphere = new SphereGeometry( 3, 64, 64 );
        this.earth = new Mesh( earthSphere, earthMaterial );

        const cloudsTexture = textureLoader.load('./earth-clouds.webp');
        const cloudsMaterial = new MeshStandardMaterial({ map: cloudsTexture, transparent: true});
        const cloudsSphere = new SphereGeometry( 3.03, 64, 64 );
        this.clouds = new Mesh( cloudsSphere, cloudsMaterial );

        // Atmosphere
        const atmosphereMaterial = new MeshStandardMaterial({
            color: new Color(0x000000), // Base blue color for the glow
            transparent: true,
            emissive: new Color(0x1e90ff), // Base emissive color
            emissiveIntensity: 0.1, // Emission strength
            blending: AdditiveBlending, // Additive blending for glow
            side: BackSide,
        });

        const atmosphereSphere = new SphereGeometry(3.05, 64, 64);
        this.atmosphere = new Mesh(atmosphereSphere, atmosphereMaterial);

        this.earth.renderOrder = 1; // Earth renders first
        this.atmosphere.renderOrder = 2; // Atmosphere renders second
        this.clouds.renderOrder = 3; // Clouds render third

        this.earth.position.y = -3
        this.clouds.position.y = -3
        this.atmosphere.position.y = -3

        scene.add(this.earth);
        scene.add(this.atmosphere);
        scene.add(this.clouds);
    }

    public update(delta: number) {
        let speed = 10
        this.earth.rotation.y += 0.01 * delta * speed;
        // this.earth.rotation.x += 0.01 * delta * speed;

        this.clouds.rotation.y += 0.012 * delta * speed;
        this.clouds.rotation.x += 0.005 * delta * speed;
    }
}