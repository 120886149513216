import * as THREE from 'three'
import { Scene } from "three";
import Earth from "./drawables/earth.ts";

export default class Game {
    private scene: Scene
    private earth: Earth

    constructor(scene: Scene) {
        this.scene = scene;

        this.earth = new Earth(scene)

        this.initScene()
    }

    private initScene() {
        const light = new THREE.DirectionalLight(0xffffff, 2);
        light.position.set(-2, 0.3, 1).normalize();
        this.scene.add(light);
    }

    public update(delta: number): void {
        this.earth.update(delta)
    }
}