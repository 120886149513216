import * as THREE from 'three';
import './style.css'
import Game from "./game.ts";

const scene = new THREE.Scene();

const renderer = new THREE.WebGLRenderer();
renderer.setSize( window.innerWidth, window.innerHeight );
renderer.setPixelRatio(window.devicePixelRatio);
document.body.appendChild(renderer.domElement);

const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
);
camera.position.z = 7;
camera.position.y = 0;
camera.rotation.x = Math.PI * -0.1

const listener = new THREE.AudioListener()
camera.add(listener)

const game = new Game(scene)

const clock = new THREE.Clock()
let delta

function animate() {
    delta = Math.min(clock.getDelta(), 0.1)
    game.update(delta)

    // cube.rotation.x += 0.5 * delta;
    // cube.rotation.y += 0.5 * delta;
    renderer.render(scene, camera)
}
renderer.setAnimationLoop( animate );

window.addEventListener('resize', onWindowResize, false)
function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, window.innerHeight)
}